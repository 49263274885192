<template>
  <div class="flyer" v-if="flyer">
    <page-title class="hidden-xs-only" :page="page" />
    <Breadcrumb class="d-none d-sm-flex px-0" :items="vBreadcrums" />
    <v-container class="flyers-title">
      <h2 v-html="flyer.title.rendered"></h2>
      <div class="d-flex align-center justify-space-between">
        <div v-html="flyer.testo_validit" @click="clicked"></div>
        <v-btn
          color="primary"
          depressed
          class="text-uppercase hidden-xs-only"
          :to="{
            name: 'Flyers'
          }"
        >
          {{ $t("flyer.back") }}
        </v-btn>
      </div>
    </v-container>

    <v-container class="upper">
      <iframe :src="iframeSrc" style="" class="mb-sm-15" />
    </v-container>

    <v-btn
      class="d-flex d-sm-none mt-8 mb-16 mx-auto"
      color="primary"
      elevation="0"
      max-width="260"
      dense
      :to="{
        name: 'Flyers'
      }"
    >
      {{ $t("flyer.back") }}
    </v-btn>
    <div class="secondary pb-5">
      <ProductListSlider
        title="Scopri le nostre promozioni"
        paginationClass="swiper-pagination-promo"
        :key="key"
        :promo="true"
        :shuffle="true"
        :showArrows="$vuetify.breakpoint.smAndUp"
      />
    </div>
  </div>
</template>
<style scoped lang="scss">
.upper {
  iframe {
    // margin-top: 84px;
    border: 0;
    width: 100%;
    height: calc(100vh - 220px);
    html,
    .main {
      background-color: $white !important;
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
      // margin-top: 58px;
      max-height: 600px;
    }
    @media (max-width: 400px) {
      max-height: 530px;
    }
  }
}
</style>
<script>
import CMSService from "~/service/cmService";
import Breadcrumb from "@/components/navigation/Breadcrumb";
import ProductListSlider from "@/components/product/ProductListSlider";
// import ProposalSlider from "@/components/proposal/ProposalSlider";
import PageTitle from "@/components/wordpress/PageTitle.vue";
import page from "~/mixins/page";
import clickHandler from "~/mixins/clickHandler";
import get from "lodash/get";

export default {
  // eslint-disable-next-line vue/no-unused-components
  name: "Flyer",
  components: {
    Breadcrumb,
    ProductListSlider,
    PageTitle
  },
  data() {
    return { header: {}, iframeLoaded: false, flyer: null };
  },
  mixins: [page, clickHandler],
  computed: {
    iframeSrc() {
      return (
        `${process.env.VUE_APP_EBSN_URL}/p/offerta/` +
        this.$route.params.volantino +
        "?notemplate=1"
      );
    },
    vBreadcrums() {
      // let b = this.breadcrumb;
      let b = [];
      b.push({
        to: { name: "Home" },
        text: "Homepage",
        exact: true
      });
      b.push({
        to: {
          name: "Flyers"
        },
        text: "Volantini",
        exact: true
      });
      if (this.flyer) {
        b.push({
          to: {
            name: "Flyer",
            params: {
              volantino: this.$route.params.volantino
            }
          },
          text: this.flyer.title.rendered,
          exact: true
        });
      }
      return b;
    }
  },
  methods: {
    async fetchFlyer(page) {
      let flyers = await CMSService.getCustomPostBySlug("offerta", page);

      if (flyers.length > 0) {
        this.flyer = flyers[0];
        this.key++;
      }
    }
  },
  metaInfo() {
    let data = this.flyer;
    console.log(data);
    return {
      title: get(
        data,
        "meta.seo_title",
        "Pagina statica",
        data ? data.title.rendered : ""
      ),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(data, "meta.seo_description")
        }
      ]
    };
  },
  async mounted() {
    this.fetchPage("volantini");
    await this.fetchFlyer(this.$route.params.volantino);
  },
  watch: {
    "$route.params.volantino": function(volantino) {
      this.fetchFlyer(volantino);
    }
  }
};
</script>
